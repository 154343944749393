import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import React from 'react';
import styles from '../styles/Home.module.css';
import MeetingComponent from '../components/MeetingComponent/MeetingComponent';

export const getServerSideProps: GetServerSideProps = async ({ query, res }) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = query.tab === 'custom' ? 1 : 0;
  return { props: { tabIndex } };
};

const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <img width={250} src="/images/hg_logo_1_color_white.png" alt="Video AI"/>
          <h2>Video AI</h2>
        </div>
        <MeetingComponent />
      </main>
    </>
  );
};

export default Home;
