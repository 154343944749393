import { useRouter } from 'next/router';
import { useEffect, useId, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../../lib/client-utils';
import styles from './MeetingComponent.module.css';
import CreatableSelect from 'react-select/creatable';

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: 'grey',
    boxShadow: state.isFocused ? '0 0 0 1px blue' : null,
    width: '200px',
    backgroundColor: 'black',
    '&:hover': {
      borderColor: 'gray',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    backgroundColor: 'black',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'lightgrey' : state.isFocused ? 'lightblue' : null,
    color: state.isSelected ? 'black' : 'grey',
    '&:hover': {
      backgroundColor: 'lightblue',
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: 'gray',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: 'grey',
  }),
};
const MeetingComponent = () => {
  const router = useRouter();
  const [agents, setAgents] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [formValue, setFormValue] = useState<{
    room: string;
    dialTo: string;
    sharedPassphrase: string;
    e2ee: boolean;
  }>({
    room: generateRoomId(),
    dialTo: '',
    sharedPassphrase: randomString(64),
    e2ee: false,
  });

  useEffect(() => {
    const fetchVoiceAgentNumbers = async () => {
      try {
        const response = await fetch(
          `/api/hg-api/${encodeURIComponent(`voice-ai/agents`)}?limit=0&offset=0`,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        const agents = result.response_data.map(({ number }: any) => {
          return {
            label: number,
            value: number,
          };
        });
        setAgents(agents);
      } catch (error) {
        console.error(`Error while fetching property`);
      }
    };
    const fetchRooms = async () => {
      try {
        const response = await fetch(
          `/api/hg-api/${encodeURIComponent(`room/all`)}?limit=0&offset=0`,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        const agents = result.response_data.room.map(({ number }: any) => {
          return {
            label: number,
            value: number,
          };
        });
        setRooms(agents);
      } catch (error) {
        console.error(`Error while fetching property`);
      }
    };
    fetchVoiceAgentNumbers();
    fetchRooms();
  }, []);

  const startMeeting = () => {
    const roomNumber = formValue.room;
    const dialTo = (formValue.dialTo as unknown as any)?.value || formValue.dialTo;
    if (formValue.e2ee) {
      router.push(
        `/rooms/${roomNumber}#${encodePassphrase(formValue.sharedPassphrase)}${
          dialTo ? `?dialTo=${dialTo}` : ''
        }`,
      );
    } else {
      router.push(`/rooms/${roomNumber}${dialTo ? `?dialTo=${dialTo}` : ''}`);
    }
  };

  const startMeetingAlexaRoom = () => {
    const roomNumber = formValue.room;
    const dialTo = (formValue.dialTo as unknown as any)?.value || formValue.dialTo;
    if (formValue.e2ee) {
      router.push(
        `/alexa-room/${roomNumber}#${encodePassphrase(formValue.sharedPassphrase)}${
          dialTo ? `?dialTo=${dialTo}` : ''
        }`,
      );
    } else {
      router.push(`/alexa-room/${roomNumber}${dialTo ? `?dialTo=${dialTo}` : ''}`);
    }
  };

  const formatGroupLabel = (data: any) => (
    <div className={styles.groupStyle}>
      <span>{data.label}</span>
      <span className={styles.groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <div className={styles.tabContent}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <label htmlFor="dial-to">Dial To</label>
        <CreatableSelect
          instanceId={useId()}
          styles={customStyles}
          className="basic-single"
          classNamePrefix="select"
          isClearable={false}
          isSearchable={true}
          name="dialTo"
          value={formValue.dialTo}
          onChange={(ev: any) =>
            setFormValue({
              ...formValue,
              dialTo: ev as any,
            })
          }
          options={[
            {
              label: 'Agents',
              options: agents || [],
            },
            {
              label: 'Rooms',
              options: rooms || [],
            },
          ]}
          formatGroupLabel={formatGroupLabel}
        />
      </div>

      {router.query?.hasAlexaMeeting ? (
        <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeetingAlexaRoom}>
          Start Meeting
        </button>
      ) : (
        <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeeting}>
          Start Meeting
        </button>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={formValue.e2ee}
            onChange={(ev) =>
              setFormValue({
                ...formValue,
                e2ee: ev.target.checked,
              })
            }
          ></input>
          <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
        </div>
        {formValue.e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <label htmlFor="passphrase">Passphrase</label>
            <input
              id="passphrase"
              type="password"
              value={formValue.sharedPassphrase}
              onChange={(ev) =>
                setFormValue({
                  ...formValue,
                  sharedPassphrase: ev.target.value,
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingComponent;
